import React from "react";
import "./roadmap.scss";
import { ROADMAP } from "../../constants";
import EllipseIcon from "../../assets/icons/EllipseIcon";

const Roadmap = () => {
  return (
    <section className="roadmap container">
      <h2 className="title">Project roadmap</h2>
      <div className="roadmap-steps">
        <div className="roadmap-progress">
          {/* <div className="roadmap-progress__left"></div> */}
          <div className="roadmap-progress__filled"></div>
          {ROADMAP.map((el) => (
            <div key={el.id} className="roadmap-progress__point">
              <EllipseIcon/>
            </div>
          ))}
          <div className="roadmap-progress__right"></div>
        </div>
        {/* <div className="roadmap-progress__end"></div> */}
        {ROADMAP.map((el) => (
          <div className="roadmap-step" key={el.id}>
            <h4>{el.period}</h4>
            <p>{el.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Roadmap;
