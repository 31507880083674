import React from "react";
import { MAIL_LINK, PARTNERS } from "../../constants";
import "./partners.scss";

const Partners = () => {
  return (
    <section id="partners" className="partners container">
      <div className="partners-title title">
        <h2>Partners</h2>
        <p>
          For cooperation and collaboration, <br /> please contact{" "}
          <a href={MAIL_LINK} target="_blank" rel="noreferrer">
            office@ubc.one
          </a>
        </p>
      </div>
      <div className="partners-cards">
        {PARTNERS.map((el) => (
          <div className="partner-card" key={el.id}>
            <a
              href={el.link}
              target="_blank"
              rel="noreferrer"
              className="partner-card__image"
            >
              {typeof el.image === "string" ? (
                <img src={el.image} alt="logo" />
              ) : (
                <div className="partner-card__icon-container">
                  {el.image()}
                </div>
              )}
            </a>
            <div className="partner-card__info">
              <a
                href={el.link}
                target="_blank"
                rel="noreferrer"
                className="partner-name"
              >
                <h4>{el.name}</h4>
                <img src={el.socialIcon} alt="social-icon" />
              </a>
              <div className="partner-location">{el.location}</div>
              <div className="partner-skills">
                {el.skills.map((skill) => (
                  <p key={skill}>{skill}</p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <p className="partners-collaboration">
        For cooperation and collaboration, <br /> please contact{" "}
        <span>office@ubc.one</span>
      </p>
    </section>
  );
};

export default Partners;
