import React from "react";
import "./card.scss";

const Card = (props) => {
  const { title, link, image, texts, isComingSoon, icon, time } =
    props;
  return (
    <div className="card">
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="card-image"
      >
        {!isComingSoon ? (
          <img src={image} alt="" />
        ) : (
          <p>Coming soon</p>
        )}
      </a>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="card-name"
      >
        <h4>{title}</h4>
        {!!icon && <div className="card-name__icon">{icon}</div>}
      </a>
      <div className="card-text">
        {texts.map((t, index) => (
          <p key={index}>{t}</p>
        ))}
      </div>
      <div className="card-time">
        {time.secondPart ? (
          <>
            <div className="card-time__period">
              <h4>1st part:</h4>
              <p>{time.firstPart}</p>
            </div>
            <div className="card-time__period">
              <h4>2st part:</h4>
              <p>{time.secondPart}</p>
            </div>
          </>
        ) : (
          <div className="card-time__period">
            <h4>Time:</h4>
            <p>{time.firstPart}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
