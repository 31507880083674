import bgLeft from "./assets/images/bg-top-left.png";
import bgRight from "./assets/images/bg-top-right.png";
import Footer from "./components/Footer/footer.component";
import Home from "./components/Home/Home";
import Objectives from "./components/Objectives/objectives.component";
import Partners from "./components/Partners/partners.component";
import Projects from "./components/Projects/projects.component";
import Roadmap from "./components/Roadmap/roadmap.component";
import CurrentProject from "./components/СurrentProject/CurrentProject";

function App() {
  return (
    <div className="app">
      <img className="app-bg app-bg__left" src={bgLeft} alt="bg-left" />
      <img className="app-bg app-bg__right" src={bgRight} alt="bg-right" />
      <Home />
      <CurrentProject />
      <Roadmap />
      <Projects />
      <Objectives />
      <Partners />
      <Footer />
    </div>
  );
}

export default App;
