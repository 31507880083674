import React from "react";
import {
  PROJECTS,
} from "../../constants";
import Card from "../Card/card.component";
import bgBottom from "../../assets/images/bg-bottom.png";
import bgMedium from "../../assets/images/bg-medium.png";
import "./projects.scss";

const Projects = () => {
  return (
    <section id="projects" className="projects-wrapper">
      <img className="projects-bg__medium" src={bgMedium}  alt={bgMedium}/>
      <div className="container">
        <h2 className="objectives-title title">UBC Projects:</h2>
        <div className="projects-items">
          {PROJECTS.sort((a, b) => {
            return b.id - a.id
          }).map((el, index) => (
            <div key={el.id} className={index === 1 ? 'projects__second-column' : 'projects__first-column'}>
            <Card  {...el}
            />
            </div>
          ))}
          {/*<div className="projects__first-column">*/}
          {/*  {PROJECTS_COLUMN_ONE.map((el) => (*/}
          {/*    <Card key={Math.random()} {...el} />*/}
          {/*  ))}*/}
          {/*</div>*/}
          {/*<div className="projects__second-column">*/}
          {/*  {PROJECTS_COLUMN_SECOND.map((el) => (*/}
          {/*    <Card key={Math.random()} {...el} />*/}
          {/*  ))}*/}
          {/*</div>*/}
        </div>
        <div className="projects__mobile">
          {PROJECTS.sort((a, b) => {
            return b.id -a.id
          }).map((el) => (
            <Card key={el.id} {...el} />
          ))}
        </div>
      </div>
      <img className="projects-bg__bottom" src={bgBottom} />
    </section>
  );
};

export default Projects;
