import React from "react";

const EllipseIcon = (props) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="5.5"
        cy="5.5"
        r="5"
        fill="#FCFCFC"
        stroke="url(#paint0_linear_140_131)"
      />
      <circle cx="5.5" cy="5.5" r="2.5" fill="url(#paint1_linear_140_131)" />
      <defs>
        <linearGradient
          id="paint0_linear_140_131"
          x1="0"
          y1="0"
          x2="9.73278"
          y2="-2.0195e-09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A7DC5" />
          <stop offset="1" stopColor="#1E5F6C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_140_131"
          x1="3"
          y1="3"
          x2="7.42399"
          y2="3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A7DC5" />
          <stop offset="1" stopColor="#1E5F6C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EllipseIcon;
