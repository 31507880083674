import React from "react";

const YoutubeIcon = () => {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1848 3.94708H5.81524C2.60357 3.94708 0 6.45767 0 9.55464V17.4454C0 20.5424 2.60357 23.053 5.81524 23.053H22.1848C25.3964 23.053 28 20.5424 28 17.4454V9.55464C28 6.45767 25.3964 3.94708 22.1848 3.94708ZM18.252 13.8839L10.5954 17.4052C10.3914 17.4991 10.1557 17.3556 10.1557 17.1377V9.875C10.1557 9.65397 10.3976 9.51071 10.602 9.61063L18.2586 13.352C18.4862 13.4632 18.4823 13.778 18.252 13.8839Z"
        fill="#F61C0D"
      />
    </svg>
  );
};

export default YoutubeIcon;
