import React from "react";
import "./footer.scss";
import logo from "../../assets/images/ubc-logo.webp";
import { FOOTER_CONTACTS } from "../../constants";

const  Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer className="footer container">
      <div className="footer-info">
        <div className="footer-logo">
          <img src={logo} alt="ubs-logo" />
        </div>
        <div className="footer-contacts">
          {FOOTER_CONTACTS.map((el) => (
            <div className="footer-contact" key={el.id}>
              <h4>{el.field}</h4>
              {el.link !== null ? (
                <a href={el.link} target="_blank" rel="noreferrer">
                  {el.value}
                </a>
              ) : (
                <p>{el.value}</p>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="footer-description">
        <div className="footer-description__text">
          The project office has no state jurisdiction and <br />{" "}
          operates on the international Internet. UBC Inc. <br />{" "}
          projects are implemented within independent <br /> legal
          entities located in the countries most suitable <br /> for
          their development.
        </div>
        <div className="footer-description__privacy">
          <span>Privacy Policy</span>
          <span>© UBC. All rights reserved {getCurrentYear()}.</span>
        </div>
      </div>
      <div className="footer-description__text_mobile">
        The project office has no state jurisdiction and <br />{" "}
        operates on the international Internet. UBC Inc. <br />{" "}
        projects are implemented within independent <br /> legal
        entities located in the countries most <br /> suitable for
        their development.
      </div>
      <div className="footer-contacts_mobile">
        {FOOTER_CONTACTS.map((el) => (
          <div className="footer-contact" key={el.id}>
            <h4>{el.field}</h4>
            {el.link !== null ? (
              <a href={el.link} target="_blank" rel="noreferrer">
                {el.value}
              </a>
            ) : (
              <p>{el.value}</p>
            )}
          </div>
        ))}
      </div>
      <div className="footer-description__privacy_mobile">
        <p>Privacy Policy</p>
        <p>© UBC. All rights reserved {getCurrentYear()}.</p>
      </div>
    </footer>
  );
};

export default Footer;
