import React from "react";

const ZenIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_122_115)">
        <path
          d="M14.3143 14.3143C12.4286 16.26 12.2914 18.6857 12.1286 24C17.0829 24 20.5029 23.9829 22.2686 22.2686C23.9829 20.5029 24 16.92 24 12.1286C18.6857 12.3 16.26 12.4286 14.3143 14.3143ZM0 12.1286C0 16.92 0.0171428 20.5029 1.73143 22.2686C3.49714 23.9829 6.91714 24 11.8714 24C11.7 18.6857 11.5714 16.26 9.68571 14.3143C7.74 12.4286 5.31429 12.2914 0 12.1286ZM11.8714 0C6.92571 0 3.49714 0.0171428 1.73143 1.73143C0.0171428 3.49714 0 7.08 0 11.8714C5.31429 11.7 7.74 11.5714 9.68571 9.68571C11.5714 7.74 11.7086 5.31429 11.8714 0ZM14.3143 9.68571C12.4286 7.74 12.2914 5.31429 12.1286 0C17.0829 0 20.5029 0.0171428 22.2686 1.73143C23.9829 3.49714 24 7.08 24 11.8714C18.6857 11.7 16.26 11.5714 14.3143 9.68571Z"
          fill="#2C3036"
        />
        <path
          d="M24 12.1286V11.8714C18.6857 11.7 16.26 11.5714 14.3143 9.68571C12.4286 7.74 12.2914 5.31429 12.1286 0H11.8714C11.7 5.31429 11.5714 7.74 9.68571 9.68571C7.74 11.5714 5.31429 11.7086 0 11.8714V12.1286C5.31429 12.3 7.74 12.4286 9.68571 14.3143C11.5714 16.26 11.7086 18.6857 11.8714 24H12.1286C12.3 18.6857 12.4286 16.26 14.3143 14.3143C16.26 12.4286 18.6857 12.2914 24 12.1286Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_122_115">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ZenIcon;
